import React from 'react'
import { graphql } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow } from "../helpers/transitionHelper"
import {Helmet} from "react-helmet";
import Post from '../components/Post'

export const query = graphql`
  query ResearchPostTemplateQuery($id: String!) {
    sanitySiteSettings {
      title
    }
    post: sanityResearch(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawBody
      _rawImage
      date
      authors {
        name
      }
    }
  }
`

const ResearchPostTemplate = props => {
  const { data } = props
  const post = data && data.post
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <Helmet>
        <title>{data.post.title} - {data.sanitySiteSettings.title}</title>
      </Helmet>
      <motion.div variants={fadeSlow}>
         <motion.div variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: props.childAnimationDelay
          }}
        }}>
          <Post post={data.post} image={post._rawImage}/>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default ResearchPostTemplate